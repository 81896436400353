/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2024-01-02 10:05:54
 * @LastEditTime: 2024-10-16 12:09:48
 * @FilePath: \rna-browser\src\store\modules\rna_browser_settings.ts
 */

import { regionString2Obj } from '@/assets/utils/base'
import { createSlice } from '@reduxjs/toolkit'

export interface IHistoryItem {
  id: string
  time: string
  trans_id: string
  trans_name: string
  trans_type: string
  region: string
}

export interface ICurrentRegion {
  chr: string
  start: number
  end: number
  strand?: string
  search?: string
  by?: string
}

export interface ISelectedRegion {
  color: string
  start: number
  end: number
  id: string
  name: string
  genome: {
    chr: string
    start: number
    end: number
  }
}

type AxisType = 'RNA' | 'DNA' | 'PROTEIN' | 'GENE' | 'CDS'

interface IRnaBrowserSettingsState {
  starRegions: ICurrentRegion[] | string
  historyRegions: IHistoryItem[] | string
  currentGenomeRegion: ICurrentRegion | string
  axisType: AxisType
  trackListData: any
  channelNewData: any[]
  selectedRegions: ISelectedRegion[] | string
  selectedRegionSetting: any
  mappedRegions: any[] | string
}

const rnaBrowserSettingsInitialState: IRnaBrowserSettingsState = {
  starRegions: '',
  historyRegions: '',
  currentGenomeRegion: '',
  trackListData: {},
  axisType: 'RNA',
  channelNewData: [],
  selectedRegions: [],
  selectedRegionSetting: '',
  mappedRegions: []
}

export const rnaBrowserSettingsSlice = createSlice({
  name: 'rnaBrowserSettings',
  initialState: rnaBrowserSettingsInitialState,
  reducers: {
    addCurrentRegion(state, { payload }) {
      console.log('addCurrentRegion', payload)
      const regionObj = regionString2Obj(payload)
      state.currentGenomeRegion = regionObj
    },
    addStarStarRegion(state, { payload }) {
      // state.starRegions = { ...state.settings, starRegions: starRegions }
      if (state.starRegions !== '') {
        state.starRegions = [...state.starRegions, payload]
      } else {
        state.starRegions = [payload]
      }
    },
    deleteStarRegion: (state, action) => {
      if (typeof state.starRegions !== 'string') {
        state.starRegions = state.starRegions.filter((item) => JSON.stringify(item) !== JSON.stringify(action.payload))
        // state.starRegions = state.starRegions.filter((item) => item !== action.payload)
        // state.starRegions = state.starRegions.filter((item) => item !== action.payload)
        console.log('deleteStarRegion', state.starRegions, typeof state.starRegions)
      }
    },
    addHistoryRegion(state, { payload }) {
      console.log('addHistoryRegion')
      if (state.historyRegions !== '' && state.historyRegions !== undefined) {
        state.historyRegions = [...state.historyRegions, payload]
      } else {
        state.historyRegions = [payload]
      }
    },
    changeAxisType(state, { payload }) {
      state.axisType = payload
    },
    setTrackListData(state, { payload }) {
      state.trackListData = payload
    },
    addChannelNewData(state, { payload }) {
      // state.channelNewData = payload
      // console.log('addChannelNewData', payload)
      // if (state.channelNewData.length !== 0) {
      // payload.forEach((element) => {
      //   state.channelNewData = [...state.channelNewData, element]
      // })
      // } else {
      state.channelNewData = payload
      // }
    },
    setSelectedRegions(state, { payload }) {
      console.log('setSelectedRegions', payload)
      state.selectedRegions = JSON.stringify(payload)
    },
    setMappedRegions(state, { payload }) {
      console.log('setMappedRegions', payload)
      state.mappedRegions = JSON.stringify(payload)
    },
    setSelectedRegionSetting(state, { payload }) {
      state.selectedRegionSetting = JSON.stringify(payload)
    }
  }
})

export const {
  addCurrentRegion,
  addStarStarRegion,
  deleteStarRegion,
  addHistoryRegion,
  changeAxisType,
  setTrackListData,
  addChannelNewData,
  setSelectedRegions,
  setSelectedRegionSetting,
  setMappedRegions
} = rnaBrowserSettingsSlice.actions

export default rnaBrowserSettingsSlice.reducer
