/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-08-28 17:42:59
 * @LastEditTime: 2024-08-07 15:38:05
 * @FilePath: \rna-browser\src\store\modules\rna_expression.ts
 */
import { getExpressionImage } from '@/service/modules/rna_expression'
import { IExpressionImageOpts } from '@/service/modules/rna_expression'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'

export interface IRnaExpressionState {
  id: string
  cellExpression: any
  tissueExpression: any
}

const rnaExpressionInitialState: IRnaExpressionState = {
  id: nanoid(),
  cellExpression: {},
  tissueExpression: {}
}

export const fetchREImageAction = createAsyncThunk('REImage', async (args: IExpressionImageOpts, { dispatch }) => {
  const res = await getExpressionImage(args)
  console.log('fetchREImageAction', res)
  if (res.status === 200) {
    const expressionImage = res.data.results.expression_img
    dispatch(changeRNAExpressionIdAction(res.data.id))
    dispatch(changeCellExpressionAction(expressionImage.cell_expression))
    dispatch(changeTissueExpressionAction(expressionImage.tissue_expression))
  } else {
    dispatch(changeCellExpressionAction([]))
    dispatch(changeTissueExpressionAction([]))
  }
})

const rnaExpressionSlice = createSlice({
  name: 'rnaExpression',
  initialState: rnaExpressionInitialState,
  reducers: {
    changeRNAExpressionIdAction(state, { payload }) {
      state.id = payload
    },
    changeCellExpressionAction(state, { payload }) {
      state.cellExpression = JSON.parse(JSON.stringify(payload))
    },
    changeTissueExpressionAction(state, { payload }) {
      state.tissueExpression = JSON.parse(JSON.stringify(payload))
    }
  }
})

export const { changeRNAExpressionIdAction, changeCellExpressionAction, changeTissueExpressionAction } =
  rnaExpressionSlice.actions
export default rnaExpressionSlice.reducer
