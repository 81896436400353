/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-06-24 14:22:04
 * @LastEditTime: 2024-10-26 23:48:14
 * @FilePath: \rna-browser\src\service\modules\transcript.ts
 */
import { zlRequest, mainRequest, ensemblRequest, gtexRequest, rnacentralRequest } from '..'

export interface IRNA2dStructureSearchOpts {
  transcriptome: string
  search: string
}

export interface ITranscriptListSearchOpts {
  search: string
  transcriptome: string
}

export interface ITranscriptTrackSearchOpts {
  transcriptome: string
  search: string
  by: string
  tissue?: string
  offset?: number
  limit?: number
  source: string
}

export interface ITranscriptSequenceOpts {
  transcriptome: string
  search: string
  by: string
  offset: number
}

export interface IVarAnnoOpts {
  chrom: string
  start: number
  end: number
}

export interface ITranscriptSequenceOpts {
  transcriptome: string
  search: string
  by: string
  offset: number
}

export interface IRegionOpts {
  transcriptome: string
  search: string
  by: string
}

export interface IBrowserRegionOpts {
  transcriptome: string
  search: string
  by: string
  offset: number
}

export function getRegion(params: IRegionOpts) {
  return mainRequest.get({
    url: `/region/${params.transcriptome}/${params.search}/${params.by}/`
  })
}

export function getBrowserRegion(params: IBrowserRegionOpts) {
  return mainRequest.get({
    // url: `/browser_region/${params.transcriptome}/${params.search}/${params.by}/${params.offset}/`
    url: `/browser_region/${params.transcriptome}/${params.search}/${params.by}/2000/`
  })
}

export function getRegions(params) {
  return zlRequest.get({
    url: '/hg38/genes/refGene/queryRegion',
    params: params
  })
}

export function getModification(params: ITranscriptTrackSearchOpts) {
  return mainRequest.get({
    url: `/modification/${params.transcriptome}/${params.search}/${params.by}/${params.offset}/${params.limit}/`
  })
}

export function getTranscriptList(params: ITranscriptListSearchOpts) {
  return mainRequest.get({
    url: `/trans/${params.search}/${params.transcriptome}/`
  })
}

export function getTranscriptTrackRegions(params: ITranscriptTrackSearchOpts) {
  return mainRequest.get({
    url: `/rna/${params.transcriptome}/${params.search}/${params.by}/${params.offset}/${params.limit}/`
  })
}

export function getTranscriptSequence(params: ITranscriptSequenceOpts) {
  return mainRequest.get({
    url: `/sequence/${params.transcriptome}/${params.search}/${params.by}/${params.offset}/`
  })
}

export function getCCRE(params: ITranscriptTrackSearchOpts) {
  return mainRequest.get({
    url: `/ccre/${params.transcriptome}/${params.search}/${params.by}/${params.offset}/${params.limit}/`
  })
}

export function getRBP(params: ITranscriptTrackSearchOpts) {
  return mainRequest.get({
    url: `/rbp/${params.transcriptome}/${params.search}/${params.by}/${params.offset}/${params.limit}/`
  })
}

export function getEQTL(params: ITranscriptTrackSearchOpts) {
  return mainRequest.get({
    url: `/eqtl/${params.transcriptome}/${params.search}/${params.by}/${params.offset}/${params.limit}/`
  })
}

export function getSQTL(params: ITranscriptTrackSearchOpts) {
  return mainRequest.get({
    url: `/sqtl/${params.transcriptome}/${params.search}/${params.by}/${params.offset}/${params.limit}/`
  })
}

export function getTransModel(params: ITranscriptTrackSearchOpts) {
  return mainRequest.get({
    url: `/trans_model/${params.transcriptome}/${params.search}/${params.by}/${params.offset}/${params.limit}/`
  })
}

export function getExonExpression(params: ITranscriptTrackSearchOpts) {
  return mainRequest.get({
    url: `/exon_expression/${params.transcriptome}/${params.search}/${params.by}`
  })
}

export function getTransExpression(params: ITranscriptTrackSearchOpts) {
  return mainRequest.get({
    url: `/trans_expression/${params.transcriptome}/${params.tissue}/${params.search}/${params.by}/${params.offset}/${params.limit}/`
  })
}

export function getJunctionExpression(params: ITranscriptTrackSearchOpts) {
  return mainRequest.get({
    url: `/junction_expression/${params.transcriptome}/${params.tissue}/${params.search}/${params.by}/${params.offset}/${params.limit}/`
  })
}

export function getRNA2dStructure(params: IRNA2dStructureSearchOpts) {
  return mainRequest.get({
    url: `/r2dstructure/${params.transcriptome}/${params.search}/`
  })
}

export function getVarAnno(params: IVarAnnoOpts) {
  return gtexRequest.get({
    url: `/dataset/functionalAnnotation`,
    params: {
      datasetId: 'gtex_v8',
      chromosome: params.chrom,
      start: params.start,
      end: params.end,
      page: 0,
      itemsPerPage: 10000
    }
  })
}

export function getMoreRegions(params) {
  const { orgname, chr, start, end } = params
  return rnacentralRequest.get({
    url: `/overlap/region/${orgname}/${chr}:${start}-${end}`
    // url: '/overlap/region/homo_sapiens/2:39,745,816-39,826,679'
  })
}

export function getSequence(params) {
  const { chr, start, end } = params
  return ensemblRequest.get({
    url: `/sequence/region/homo_sapiens/${chr}:${start}-${end}?content-type=text/plain`
    // url: '/sequence/region/homo_sapiens/14:49585001-49589000?content-type=text/plain'
    // url: '/overlap/region/homo_sapiens/12:53930485-53994549?feature=transcript;feature=exon;feature=cds;content-type=application/json'
  })
}

export function getRfam(params) {
  const { taxid, rnaid } = params
  return rnacentralRequest.get({
    url: `/rna/${rnaid}/rfam-hits/${taxid}?page_size=50`
    // url: '/rna/URS00000478B7/rfam-hits/9606?page_size=50'
  })
}

export function getR2dStructure(params) {
  const { rnaid } = params
  return rnacentralRequest.get({
    url: `/rna/${rnaid}/2d/`
  })
}
