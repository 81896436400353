/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-03-22 10:41:48
 * @LastEditTime: 2023-09-29 14:22:50
 * @FilePath: \rbrowser\src\service\config\index.ts
 */
export const TIME_OUT = 10000

// 区分生产环境和开发环境
let BASE_URL = ''
let ENSEMBL_URL = ''
let RNACENTRAL_URL = ''
let MAIN_URL = ''
let GTEX_URL = ''

if (process.env.NODE_ENV === 'development') {
  // MAIN_URL = 'https://3.15.148.186:8002/api/v1/'
  MAIN_URL = 'https://api.rbrowser.org/api/v1/'
  BASE_URL = 'https://lambda.epigenomegateway.org/v2/'
  ENSEMBL_URL = 'https://rest.ensembl.org/'
  RNACENTRAL_URL = 'https://rnacentral.org/api/v1/'
  GTEX_URL = 'https://gtexportal.org/api/v2/'
} else {
  MAIN_URL = 'https://api.rbrowser.org/api/v1/'
  BASE_URL = 'https://lambda.epigenomegateway.org/v2/'
  ENSEMBL_URL = 'https://rest.ensembl.org/'
  RNACENTRAL_URL = 'https://rnacentral.org/api/v1/'
  GTEX_URL = 'https://gtexportal.org/api/v2/'
}
// console.log(process.env.REACT_APP_BASE_URL)

export { BASE_URL, ENSEMBL_URL, RNACENTRAL_URL, MAIN_URL, GTEX_URL }
