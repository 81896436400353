/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2024-01-02 10:04:25
 * @LastEditTime: 2024-07-30 18:01:20
 * @FilePath: \rna-browser\src\store\index.ts
 */
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { useSelector, useDispatch, TypedUseSelectorHook, shallowEqual } from 'react-redux'
import rbSettings from './modules/rna_browser_settings'
import rnaBrowser from './modules/rna-browser'
import rnaExpression from './modules/rna_expression'

const reducers = combineReducers({
  rbSettings: rbSettings,
  rnaBrowser: rnaBrowser,
  rnaExpression: rnaExpression
})

// 持久化配置
const persistConfig = {
  key: 'primary',
  storage,
  // whitelist: ['rbSettings']
  whitelist: []
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false // 忽略不可序列化的数据
    })
})

// 获取 store 的数据类型
type GetStateFnType = typeof store.getState
type IRootState = ReturnType<GetStateFnType>
type DispatchType = typeof store.dispatch

// 带类型约束的 useSelector
export const useAppSelecter: TypedUseSelectorHook<IRootState> = useSelector
export const useAppDispatch: () => DispatchType = useDispatch
export const shallowEqualApp = shallowEqual

export default store
