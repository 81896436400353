/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2024-01-02 14:56:07
 * @LastEditTime: 2024-01-03 10:33:33
 * @FilePath: \rna-browser\src\assets\utils\base.ts
 */
// add unique element to array
export const addUniqueElement = (arr, element) => {
  const uniqueSet = new Set(arr)
  uniqueSet.add(element)
  return Array.from(uniqueSet)
}

// get region

export const regionString2Obj = (input) => {
  // let str = 'chr1:154196636-154206971'
  if (typeof input === 'object') return input
  const regex = /([a-zA-Z\d]+):(\d+)-(\d+)/
  const matches = input.match(regex)

  if (matches) {
    const chromosome = matches[1] // "chr1"
    const start = matches[2] // "154196636"
    const end = matches[3] // "154206971"
    console.log(chromosome, start, end)
    return {
      chr: chromosome,
      start: start,
      end: end
    }
  } else {
    console.log('No match found')
  }
}

// compare region
export const compareRegion = (obj1, obj2) => {
  return obj1.chrom === obj2.chrom && obj1.start === obj2.start && obj1.end === obj2.end
}

// check contain region
export const containsRegion = (array, obj) => {
  console.log('containsRegion', array, typeof array, obj)
  if (array === undefined || array.length === 0 || typeof array === 'string') return false
  return array.some((item) => item.chrom === obj.chrom && item.start === obj.start && item.end === obj.end)
}
