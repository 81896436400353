/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-03-22 10:34:41
 * @LastEditTime: 2023-08-28 18:24:19
 * @FilePath: \rbrowser\src\service\request\index.ts
 */
import axios from 'axios'
// import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import type { AxiosInstance } from 'axios'
import type { ZLRequestConfig } from './type'

class ZLRequest {
  instance: AxiosInstance

  // Global interceptor
  constructor(config: ZLRequestConfig) {
    this.instance = axios.create(config)

    this.instance.interceptors.request.use(
      (config) => {
        return config
      },
      (err) => {
        return err
      }
    )
    this.instance.interceptors.response.use(
      (res) => {
        return res
      },
      (err) => {
        console.error(err)
        return err
      }
    )

    // Interceptor for zlRequest instance
    this.instance.interceptors.request.use(config.interceptors?.requestSuccessFn, config.interceptors?.requestFailureFn)
    this.instance.interceptors.response.use(
      config.interceptors?.responseSuccessFn,
      config.interceptors?.responseFailureFn
    )
  }

  // Interceptor for call instance
  request<T = any>(config: ZLRequestConfig<T>) {
    // TODO: check url
    // console.log(config)

    if (config.interceptors?.requestSuccessFn) {
      config = config.interceptors.requestSuccessFn(config)
    }

    return new Promise<T>((resolve, reject) => {
      this.instance
        .request<any, T>(config)
        .then((res) => {
          if (config.interceptors?.responseSuccessFn) {
            res = config.interceptors.responseSuccessFn(res)
          }
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  get<T = any>(config: ZLRequestConfig<T>) {
    return this.request({ ...config, method: 'GET' })
  }
  post<T = any>(config: ZLRequestConfig<T>) {
    return this.request({ ...config, method: 'POST' })
  }
  delete<T = any>(config: ZLRequestConfig<T>) {
    return this.request({ ...config, method: 'DELETE' })
  }
  patch<T = any>(config: ZLRequestConfig<T>) {
    return this.request({ ...config, method: 'PATCH' })
  }
}

export default ZLRequest
