/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-03-22 10:42:28
 * @LastEditTime: 2023-03-23 16:25:35
 * @FilePath: \epitranscriptome_browser\src\service\index.ts
 */
import { BASE_URL, ENSEMBL_URL, RNACENTRAL_URL, MAIN_URL, GTEX_URL, TIME_OUT } from './config'
import ZLRequest from './request'

const zlRequest = new ZLRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT
})

const mainRequest = new ZLRequest({
  baseURL: MAIN_URL,
  timeout: TIME_OUT
})

const ensemblRequest = new ZLRequest({
  baseURL: ENSEMBL_URL,
  timeout: TIME_OUT
})

const gtexRequest = new ZLRequest({
  baseURL: GTEX_URL,
  timeout: TIME_OUT
})

const rnacentralRequest = new ZLRequest({
  baseURL: RNACENTRAL_URL,
  timeout: TIME_OUT
})
export { zlRequest, ensemblRequest, rnacentralRequest, mainRequest, gtexRequest }
