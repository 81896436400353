/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-08-28 17:39:46
 * @LastEditTime: 2024-08-07 15:38:33
 * @FilePath: \rna-browser\src\service\modules\rna_expression.ts
 */
import { mainRequest } from '..'

export interface IExpressionImageOpts {
  organism: string
  search: string
  by: string
}

export function getExpressionImage(params: IExpressionImageOpts) {
  return mainRequest.get({
    url: `/expression_img/${params.organism}/${params.search}/${params.by}/`
  })
}
