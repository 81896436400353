import React, { memo } from 'react'
import type { FC, ReactNode } from 'react'
import { CssBaseline, CssVarsProvider, useTheme } from '@mui/joy'
import { Global } from '@emotion/react'

export const BaimoScrollbar = () => {
  const theme = useTheme()
  return (
    <Global
      styles={{
        '*': {
          scrollbarWidth: 'thin',
          scrollbarColor: `${theme.palette.neutral.outlinedBorder} transparent`
          // msOverflowStyle: 'none' /* Hide scrollbar for IE and Edge */
        },
        '*::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
          display: 'none'
        },
        '*::-webkit-scrollbar-track': {
          // background: 'transparent'
        },
        // '*::-webkit-scrollbar-thumb': {
        //   backgroundColor: theme.palette.neutral.outlinedBorder,
        //   borderRadius: '10px',
        //   border: '2px solid transparent',
        //   backgroundClip: 'content-box'
        // },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'red'
        }
      }}
    />
  )
}
