/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-12-01 11:23:07
 * @LastEditTime: 2024-10-17 16:01:47
 * @FilePath: \rna-browser\src\App.tsx
 */
import React, { useState, useEffect, Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import { Layout, Row, Col } from 'antd'
import Rna2dStructure from './views/main/c-views/rna-2d-structure'
import Transcriptome from './views/main/c-views/transcriptome'
// import Loading from './assets/img/loading-point.svg'
import Loading from './assets/img/loading-bar-black.svg'
import { CssBaseline, CssVarsProvider, useTheme } from '@mui/joy'
import { BaimoScrollbar } from './components/scrollbar/baimo-scrollbar'

import routes from './router'
// import { events, EmitterContext } from '@/hooks/main'
// import { useEventEmitter } from 'ahooks'

// import { useEventEmitter } from 'ahooks'

// const testEvent = useEventEmitter()

// export const events = {
//   events: {
//     test: testEvent
//   }
// }

// export const EmitterContext = React.createContext(events)

function App() {
  return (
    <div>
      {/* <Row justify='center' align='middle' className='base-loading' style={{ height: '100vh' }}>
        <Col>
          <center>
            <p style={{ margin: '2px' }}>RNA Browser</p>
            <img src={Loading} alt='' height={30} width={50} />
          </center>
        </Col>
      </Row> */}
      {/* <EmitterContext.Provider value={events}> */}
      <BaimoScrollbar />
      <Suspense
        fallback={
          <Row justify='center' align='middle' className='base-loading' style={{ height: '100vh' }}>
            <Col>
              <center>
                <p>RNA Browser</p>
                <img src={Loading} alt='' height={30} width={50} />
              </center>
            </Col>
          </Row>
        }>
        {useRoutes(routes)}
      </Suspense>
      {/* </EmitterContext.Provider> */}
    </div>
  )
}

export default App
