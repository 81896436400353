/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-12-01 11:23:07
 * @LastEditTime: 2024-10-14 21:39:30
 * @FilePath: \rna-browser\src\index.tsx
 */
import React from 'react'
import ReactDOM from 'react-dom/client'
import { createRoot } from 'react-dom/client'
import App from './App'
import { HashRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import store from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { StyledEngineProvider } from '@mui/joy/styles'
import { extendTheme, CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles'

const persistor = persistStore(store)

import 'normalize.css'
import './assets/css/index.less'
import './assets/css/overwrite.css'

const theme = extendTheme({
  components: {
    JoyButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:focus-visible': {
            outline: 'none',
            border: '1px solid',
            borderColor: theme.vars.palette.primary[500]
          }
        })
      }
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <HashRouter>
    <Provider store={store}>
      {/* <ThemeProvider theme={theme}> */}
      {/* <StyledEngineProvider injectFirst> */}
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
      {/* </StyledEngineProvider> */}
      {/* </ThemeProvider> */}
    </Provider>
  </HashRouter>
)
