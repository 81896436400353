/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2024-01-02 11:21:06
 * @LastEditTime: 2024-10-27 02:03:00
 * @FilePath: \rna-browser\src\store\modules\rna-browser.ts
 */
import { IBrowserRegionOpts, getBrowserRegion, getRegion } from '@/service/modules/transcript'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { addCurrentRegion } from './rna_browser_settings'
import { regionString2Obj } from '@/assets/utils/base'
import { nanoid } from 'nanoid'
import { updateLocalStorage } from '@/renderer/transcript-renderer/utils/storage'

export interface IRegionOpts {
  transcriptome: string
  search: string
  by: string
}

export const fetchTargetRegion = (dispatch, opts: IRegionOpts) => {
  console.log('rna_browser fetchRNABrowserInfo')
  // transcript browser ==========================================================
  const { transcriptome, search, by } = opts
  const getRegionParams = {
    transcriptome: transcriptome,
    search: search,
    by: by
  }
  // dispatch(fetchTBSequenceAction(getRegionParams))

  // const trackParams = {
  //   transcriptome: transcriptome,
  //   search: search,
  //   by: by,
  //   offset: offset,
  //   limit: limit
  // }
  // dispatch(fetchTBInfoAction(trackParams))

  dispatch(fetchTargetRegionAction(opts))
}

const initialState = {
  genomeRegion: {}
}

export const fetchTargetRegionAction2 = (params) => {
  console.log('fetchTargetRegionAction')
}

export const fetchTargetRegionAction = createAsyncThunk('region', async (args: IRegionOpts, { dispatch }) => {
  const res = await getRegion(args)
  if (res.status === 200) {
    dispatch(changeTargetRegionAction(res.data.results))
    // set current region
    // dispatch(addCurrentRegion(res.data.results))
  } else dispatch(changeTargetRegionAction({}))
})

export const fetchBrowserRegionAction = createAsyncThunk('region', async (args: IBrowserRegionOpts, { dispatch }) => {
  const res = await getBrowserRegion(args)
  if (res.status === 200) {
    const results = res.data.results
    const region = getMaxRegion(results)
    dispatch(changeTargetRegionAction(res.data.results))
    console.log('fetchBrowserRegionAction', region)
    // set current region
    // dispatch(addCurrentRegion(res.data.results))
  } else dispatch(changeTargetRegionAction({}))
})

const getMaxRegion = (results) => {
  if (results['model_region']['start'] !== -1) {
    return results['model_region']
  } else if (results['trans_region']['start'] !== -1) {
    return results['trans_region']
  }
}

const rnaBrowserSlice = createSlice({
  name: 'rnaBrowser',
  initialState,
  reducers: {
    changeTargetRegionAction(state, { payload }) {
      if (typeof payload === 'string') {
        const regionObj = regionString2Obj(payload)
        state.genomeRegion = {
          id: nanoid(),
          region: [regionObj]
        }
      } else {
        state.genomeRegion = payload
      }
    }
  }
})

export const { changeTargetRegionAction } = rnaBrowserSlice.actions
export default rnaBrowserSlice.reducer
